import * as React from "react"
import { Container } from "@allied/react-web/Container"
import { Link } from "@allied/react-web/Router"
import { Meta } from "@allied/react-web/Meta"
import { useHashFragment } from "@allied/react-web/hooks"
import { useAutoPosition } from "../hooks"

export default function PrivacyPolicy(): React.JSX.Element {
  useAutoPosition()
  useHashFragment()

  return (
    <>
      <Meta>
        {{
          title: "Privacy Policy",
          description: "Learn about our commitment to safeguarding your data and ensuring your privacy. Explore our comprehensive security measures and privacy practices to ensure a safe experience on our platform."
        }}
      </Meta>
      <Container size="md">
        <div className="flex flex-col gap-8 py-6 leading-6 md:leading-8">
          <div className="flex flex-col gap-4">
            <h1 className="font-bold text-2xl md:text-3xl">Privacy Policy</h1>
            <p className="font-bold text-base">Last updated June 10, 2024</p>
            <div>
              <p>
                This privacy notice for ACS Marine & Shipping Pte Ltd <span className="font-bold">('we', 'us', or 'our')</span>, describes how and why we might collect, store, use, and/or share ('process') your information when you use our services <span className="font-bold">('Services')</span>, such as when you:
              </p>
              <ul className="list-[square] pl-4">
                <li>Visit our website at acsmarine.sg, or any website of ours that links to this privacy notice</li>
                <li>Engage with us in other related ways, including any sales, marketing, or events</li>
              </ul>
            </div>
            <h2 className="font-bold text-xl md:text-2xl">Summary of Key Points</h2>
            <ul className="flex flex-col gap-4">
              <li><span className="font-bold">What personal information do we process?</span> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about personal information you disclose to us.</li>
              <li><span className="font-bold">Do we process any sensitive personal information?</span> We do not process sensitive personal information.</li>
              <li><span className="font-bold">Do we collect any information from third parties?</span> We do not collect any information from third parties.</li>
              <li><span className="font-bold">How do we process your information?</span> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information.</li>
              <li><span className="font-bold">In what situations and with which parties do we share personal information?</span> We may share information in specific situations and with specific third parties. Learn more about when and with whom we share your personal information.</li>
              <li><span className="font-bold">How do we keep your information safe?</span> We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe.</li>
              <li><span className="font-bold">What are your rights?</span> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights.</li>
              <li><span className="font-bold">How do you exercise your rights?</span> The easiest way to exercise your rights is by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</li>
            </ul>
            <h2 className="font-bold text-xl md:text-2xl">TABLE OF CONTENTS</h2>
            <ol className="list-decimal pl-6 flex flex-col gap-2">
              <li>
                <Link to="#policy-1" className="underline hover:text-acsm-primary" hideCrawl>
                  What information do we collect?
                </Link>
              </li>
              <li>
                <Link to="#policy-2" className="underline hover:text-acsm-primary" hideCrawl>
                  How do we process your information?
                </Link>
              </li>
              <li>
                <Link to="#policy-3" className="underline hover:text-acsm-primary" hideCrawl>
                  What legal bases do we reply on to process your personal information?
                </Link>
              </li>
              <li>
                <Link to="#policy-4" className="underline hover:text-acsm-primary" hideCrawl>
                  When and with whom do we share your personal information?
                </Link>
              </li>
              <li>
                <Link to="#policy-5" className="underline hover:text-acsm-primary" hideCrawl>
                  Do we use cookies and other tracking technologies?
                </Link>
              </li>
              <li>
                <Link to="#policy-6" className="underline hover:text-acsm-primary" hideCrawl>
                  How long do we keep your information?
                </Link>
              </li>
              <li>
                <Link to="#policy-7" className="underline hover:text-acsm-primary" hideCrawl>
                  How do we keep your information safe?
                </Link>
              </li>
              <li>
                <Link to="#policy-8" className="underline hover:text-acsm-primary" hideCrawl>
                  What are your privacy rights?
                </Link>
              </li>
              <li>
                <Link to="#policy-9" className="underline hover:text-acsm-primary" hideCrawl>
                  Controls for do-not-track features
                </Link>
              </li>
              <li>
                <Link to="#policy-10" className="underline hover:text-acsm-primary" hideCrawl>
                  Do United States residents have specific privacy rights?
                </Link>
              </li>
              <li>
                <Link to="#policy-11" className="underline hover:text-acsm-primary" hideCrawl>
                  Do we make updates to this notice?
                </Link>
              </li>
              <li>
                <Link to="#policy-12" className="underline hover:text-acsm-primary" hideCrawl>
                  How can you contact us about this notice?
                </Link>
              </li>
              <li>
                <Link to="#policy-13" className="underline hover:text-acsm-primary" hideCrawl>
                  How can you review, update, or delete the data we collect from you?
                </Link>
              </li>
            </ol>
          </div>

          <div id="policy-1" className="scroll-my-20 flex flex-col gap-4">
            <h2 className="font-bold text-2xl md:text-3xl">1. What information do we collect?</h2>
            <p>
              <span className="font-bold">Personal information you disclose to us.</span> We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
            </p>
            <div>
              <p>
                <span className="font-bold">Personal Information Provided by You.</span> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
              </p>
              <ul className="list-[square] pl-4">
                <li>names</li>
                <li>phone numbers</li>
                <li>email addresses</li>
                <li>mailing addresses</li>
              </ul>
            </div>
            <p>
              <span className="font-bold">Sensitive Information.</span> We do not process sensitive information.
              All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
            </p>
            <p>
              <span className="font-bold">Information automatically collected.</span>&nbsp;
              We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
            </p>
            <p>
              Like many businesses, we also collect information through cookies and similar technologies.
            </p>
            <div>
              <p>The information we collect includes:</p>
              <ul className="list-[square] pl-4">
                <li>
                  <span className="italic">Log and Usage Data.</span> Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware settings).
                </li>
                <li>
                  <span className="italic">Device Data.</span> We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.
                </li>
                <li>
                  <span className="italic">Location Data.</span> We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.
                </li>
              </ul>
            </div>
          </div>

          <div id="policy-2" className="scroll-my-20 flex flex-col gap-4">
            <h2 className="font-bold text-2xl md:text-3xl">2. How do we process your information?</h2>
            <div>
              <p>
                <span className="font-bold">
                  We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
                </span>
              </p>
              <ul className="list-[square] pl-4">
                <li><span className="font-bold">To facilitate account creation and authentication and otherwise manage user accounts.</span> We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
                <li><span className="font-bold">To request feedback.</span> We may process your information when necessary to request feedback and to contact you about your use of our Services.</li>
                <li><span className="font-bold">To send you marketing and promotional communications.</span> We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see 'What are your privacy rights?' below.</li>
                <li><span className="font-bold">To deliver targeted advertising to you.</span> We may process your information to develop and display personalised content and advertising tailored to your interests, location, and more.</li>
                <li><span className="font-bold">To protect our Services.</span> We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</li>
                <li><span className="font-bold">To identify usage trends.</span> We may process information about how you use our Services to better understand how they are being used so we can improve them.</li>
                <li><span className="font-bold">To determine the effectiveness of our marketing and promotional campaigns.</span> We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.</li>
                <li><span className="font-bold">To save or protect an individual's vital interest.</span> We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</li>
              </ul>
            </div>
          </div>

          <div id="policy-3" className="scroll-my-20 flex flex-col gap-4">
            <h2 className="font-bold text-2xl md:text-3xl">3. What legal bases do we rely on to process your information?</h2>
            <p className="italic font-bold underline">
              If you are located in the EU or UK, this section applies to you.
            </p>
            <div>
              <p>
                The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information.
                As such, we may rely on the following legal bases to process your personal information:
              </p>
              <ul className="list-[square] pl-4">
                <li><span className="font-bold">Consent.</span> We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about withdrawing your consent.</li>
                <li>
                  <span className="font-bold">Legitimate Interests.</span> We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:
                  <ul className="list-[square] pl-4">
                    <li>Send users information about special offers and discounts on our products and services</li>
                    <li>Develop and display personalised and relevant advertising content for our users</li>
                    <li>Analyse how our Services are used so we can improve them to engage and retain users</li>
                    <li>Support our marketing activities</li>
                    <li>Diagnose problems and/or prevent fraudulent activities</li>
                    <li>Understand how our users use our products and services so we can improve user experience</li>
                  </ul>
                </li>
                <li><span className="font-bold">Legal Obligations.</span> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</li>
                <li><span className="font-bold">Vital Interests.</span> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</li>
              </ul>
            </div>
            <p className="italic font-bold underline">
              If you are located in Canada, this section applies to you.
            </p>
            <p>
              We may process your information if you have given us specific permission (i.e. express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e. implied consent). You can withdraw your consent at any time.
            </p>
            <div>
              <p>
                In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
              </p>
              <ul className="list-[square] pl-4">
                <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</li>
                <li>For investigations and fraud detection and prevention</li>
                <li>For business transactions provided certain conditions are met</li>
                <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</li>
                <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
                <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</li>
                <li>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</li>
                <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</li>
                <li>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</li>
                <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
                <li>If the information is publicly available and is specified by the regulations</li>
              </ul>
            </div>
          </div>

          <div id="policy-4" className="scroll-my-20 flex flex-col gap-4">
            <h2 className="font-bold text-2xl md:text-3xl">4. When and with whom do we share your personal information?</h2>
            <p>We may need to share your personal information in the following situations:</p>
            <ul className="list-[square] pl-4">
              <li><span className="font-bold">Business Transfers.</span> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
              <li><span className="font-bold">When we use Google Maps Platform APIs.</span> We may share your information with certain Google Maps Platform APIs (e.g. Google Maps API, Places API).</li>
              <li><span className="font-bold">Affiliates.</span> We may share your information with our affiliates, in which case we will require those affiliates to honour this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</li>
              <li><span className="font-bold">Business Partners.</span> We may share your information with our business partners to offer you certain products, services, or promotions.</li>
            </ul>
          </div>

          <div id="policy-5" className="scroll-my-20 flex flex-col gap-4">
            <h2 className="font-bold text-2xl md:text-3xl">5. Do we use cookies and other tracking technologies?</h2>
            <p>
              We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services and your account, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.
            </p>
            <p>
              We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites.
            </p>
            <p>
              To the extent these online tracking technologies are deemed to be a 'sale'/'sharing' (which includes targeted advertising, as defined under the applicable laws) under applicable US state laws, you can opt out of these online tracking technologies by submitting a request as described below under section 'Do United States residents have specific privacy rights?'
            </p>
            <p>
              Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
            </p>
            <h3 className="font-bold text-xl md:text-2xl">Google Analytics</h3>
            <p>
              We may share your information with Google Analytics to track and analyse the use of the Services. The Google Analytics Advertising Features that we may use include: Google Analytics Demographics and Interests Reporting. To opt out of being tracked by Google Analytics across the Services, visit <Link to="https://tools.google.com/dlpage/gaoptout" className="underline hover:text-acsm-primary" target="_blank" hideCrawl>https://tools.google.com/dlpage/gaoptout</Link>.
              You can opt out of Google Analytics Advertising Features through Ads Settings and Ad Settings for mobile apps. Other opt out means include <Link to="http://optout.networkadvertising.org/" className="underline hover:text-acsm-primary" target="_blank" hideCrawl>http://optout.networkadvertising.org/</Link> and <Link to="http://www.networkadvertising.org/mobile-choice" className="underline hover:text-acsm-primary" target="_blank" hideCrawl>http://www.networkadvertising.org/mobile-choice</Link>.
              For more information on the privacy practices of Google, please visit the <Link to="https://policies.google.com/privacy" className="underline hover:text-acsm-primary" target="_blank" hideCrawl>Google Privacy & Terms page</Link>.
            </p>
          </div>

          <div id="policy-6" className="scroll-my-20 flex flex-col gap-4">
            <h2 className="font-bold text-2xl md:text-3xl">6. How long do we keep your information?</h2>
            <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</p>
            <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
          </div>

          <div id="policy-7" className="scroll-my-20 flex flex-col gap-4">
            <h2 className="font-bold text-2xl md:text-3xl">7. How do we keep your information safe?</h2>
            <p>
              We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process.
              However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.
            </p>
            <p>
              Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk.
              You should only access the Services within a secure environment.
            </p>
          </div>

          <div id="policy-8" className="scroll-my-20 flex flex-col gap-4">
            <h2 className="font-bold text-2xl md:text-3xl">8. What are your privacy rights?</h2>
            <p>
              In some regions (like Singapore, the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section 'How can you contact us about this notice?' below.
            </p>
            <p>
              We will consider and act upon any request in accordance with applicable data protection laws.
            </p>
            <p>
              If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your <Link to="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" className="underline hover:text-acsm-primary" target="_blank" hideCrawl>Member State data protection authority</Link> or <Link to="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/" className="underline hover:text-acsm-primary" target="_blank" hideCrawl>UK data protection authority</Link>.
            </p>
            <p>
              If you are located in Switzerland, you may contact the <Link to="https://www.edoeb.admin.ch/edoeb/en/home.html" className="underline hover:text-acsm-primary" target="_blank" hideCrawl>Federal Data Protection and Information Commissioner</Link>.
            </p>
            <p>
              <span className="font-bold">Withdrawing your consent:</span> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section 'How can you contact us about this notice?' below.
            </p>
            <p>
              However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
            </p>
            <p>
              <span className="font-bold">Opting out of marketing and promotional communications:</span> You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in the section 'How can you contact us about this notice?' below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.
            </p>
            <h3 className="font-bold text-xl md:text-2xl">Account Information</h3>
            <p>
              If you would at any time like to review or change the information in your account or terminate your account, you can:
              <ul className="list-[square] pl-4">
                <li>Log in to your account settings and update your user account.</li>
              </ul>
            </p>
            <p>
              Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
            </p>
            <p>
              <span className="font-bold">Cookies and similar technologies:</span> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.
            </p>
          </div>

          <div id="policy-9" className="scroll-my-20 flex flex-col gap-4">
            <h2 className="font-bold text-2xl md:text-3xl">9. Controls for Do-Not-Track features</h2>
            <p>
              Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
            </p>
            <p>
              California law requires us to let you know how we respond to web browser DNT signals. Because there currently is not an industry or legal standard for recognising or honouring DNT signals, we do not respond to them at this time.
            </p>
          </div>

          <div id="policy-10" className="scroll-my-20 flex flex-col gap-4">
            <h2 className="font-bold text-2xl md:text-3xl">10. Do United States Residents have specific privacy rights?</h2>
            <h3 className="font-bold text-xl md:text-2xl">Categories of Personal Information We Collect</h3>
            <p>
              We have collected the following categories of personal information in the past twelve (12) months:
            </p>
            <table className="table-auto border-collapse border-spacing-2 border border-slate-500">
              <thead>
                <tr>
                  <th className="p-2 border border-slate-600">Category</th>
                  <th className="p-2 border border-slate-600">Examples</th>
                  <th className="p-2 border border-slate-600">Collected</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2 border border-slate-600">
                    A. Identifiers
                  </td>
                  <td className="p-2 border border-slate-600">
                    Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name
                  </td>
                  <td className="p-2 border border-slate-600">
                    NO
                  </td>
                </tr>
                <tr>
                  <td className="p-2 border border-slate-600">
                    B. Protected classification characteristics under state or federal law
                  </td>
                  <td className="p-2 border border-slate-600">
                    Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data
                  </td>
                  <td className="p-2 border border-slate-600">
                    NO
                  </td>
                </tr>
                <tr>
                  <td className="p-2 border border-slate-600">
                    C. Commercial information
                  </td>
                  <td className="p-2 border border-slate-600">
                    Transaction information, purchase history, financial details, and payment information
                  </td>
                  <td className="p-2 border border-slate-600">
                    NO
                  </td>
                </tr>
                <tr>
                  <td className="p-2 border border-slate-600">
                    D. Biometric information
                  </td>
                  <td className="p-2 border border-slate-600">
                    Fingerprints and voiceprints
                  </td>
                  <td className="p-2 border border-slate-600">
                    NO
                  </td>
                </tr>
                <tr>
                  <td className="p-2 border border-slate-600">
                    E. Internet or other similar network activity
                  </td>
                  <td className="p-2 border border-slate-600">
                    Browsing history, search history, online behaviour, interest data, and interactions with our and other websites, applications, systems, and advertisements
                  </td>
                  <td className="p-2 border border-slate-600">
                    NO
                  </td>
                </tr>
                <tr>
                  <td className="p-2 border border-slate-600">
                    F. Geolocation data
                  </td>
                  <td className="p-2 border border-slate-600">
                    Device location
                  </td>
                  <td className="p-2 border border-slate-600">
                    NO
                  </td>
                </tr>
                <tr>
                  <td className="p-2 border border-slate-600">
                    G. Audio, electronic, sensory, or similar information
                  </td>
                  <td className="p-2 border border-slate-600">
                    Images and audio, video or call recordings created in connection with our business activities
                  </td>
                  <td className="p-2 border border-slate-600">
                    NO
                  </td>
                </tr>
                <tr>
                  <td className="p-2 border border-slate-600">
                    H. Professional or employment-related information
                  </td>
                  <td className="p-2 border border-slate-600">
                    Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us
                  </td>
                  <td className="p-2 border border-slate-600">
                    NO
                  </td>
                </tr>
                <tr>
                  <td className="p-2 border border-slate-600">
                    I. Education Information
                  </td>
                  <td className="p-2 border border-slate-600">
                    Student records and directory information
                  </td>
                  <td className="p-2 border border-slate-600">
                    NO
                  </td>
                </tr>
                <tr>
                  <td className="p-2 border border-slate-600">
                    J. Inferences drawn from collected personal information
                  </td>
                  <td className="p-2 border border-slate-600">
                    Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics
                  </td>
                  <td className="p-2 border border-slate-600">
                    NO
                  </td>
                </tr>
                <tr>
                  <td className="p-2 border border-slate-600">
                    K. Sensitive personal Information
                  </td>
                  <td className="p-2 border border-slate-600">

                  </td>
                  <td className="p-2 border border-slate-600">
                    NO
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <p>
                We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:
              </p>
              <ul className="list-[square] pl-4">
                <li>Receiving help through our customer support channels;</li>
                <li>Participation in customer surveys or contests; and</li>
                <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
              </ul>
            </div>
            <div>
              <p>
                We will use and retain the collected personal information as needed to provide the Services or for:
              </p>
              <ul className="list-[square] pl-4">
                <li>Category H - As long as the user has an account with us</li>
              </ul>
            </div>
            <h3 className="font-bold text-xl md:text-2xl">Sources of Personal Information</h3>
            <p>
              Learn more about the sources of personal information we collect in 'What information do we collect?'
            </p>
            <h3 className="font-bold text-xl md:text-2xl">How We Use and Share Personal Information</h3>
            <p>
              Learn about how we use your personal information in the section, 'How do we process your information?'
            </p>
            <h4 className="font-bold text-xl md:text-2xl">Will your information be shared with anyone else?</h4>
            <p>We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information to in the section, 'When and with whom do we share your personal information?'</p>
            <p>We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be 'selling' of your personal information.</p>
            <p>We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We will not sell or share personal information in the future belonging to website visitors, users, and other consumers.</p>
            <h3 className="font-bold text-xl md:text-2xl">Your Rights</h3>
            <p>
              You have rights under certain US state data protection laws. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law. These rights include:
            </p>
            <ul className="list-[square] pl-4">
              <li><span className="font-bold">Right to know</span> whether or not we are processing your personal data</li>
              <li><span className="font-bold">Right to access</span> your personal data</li>
              <li><span className="font-bold">Right to correct</span> inaccuracies in your personal data</li>
              <li><span className="font-bold">Right to request</span> the deletion of your personal data</li>
              <li><span className="font-bold">Right to obtain a copy</span> of the personal data you previously shared with us</li>
              <li><span className="font-bold">Right to non-discrimination</span> for exercising your rights</li>
              <li><span className="font-bold">Right to opt out</span> of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ('profiling')</li>
            </ul>
            <h3 className="font-bold text-xl md:text-2xl">How to Exercise Your Rights</h3>
            <p>
              To exercise these rights, you can contact us by referring to the contact form of this website.
            </p>
            <p>
              We will honour your opt-out preferences if you enact the <Link to="https://globalprivacycontrol.org/" className="underline hover:text-acsm-primary" target="_blank" hideCrawl>Global Privacy Control (GPC)</Link> opt-out signal on your browser.
            </p>
            <p>
              Under certain US state data protection laws, you can designate an authorised agent to make a request on your behalf. We may deny a request from an authorised agent that does not submit proof that they have been validly authorised to act on your behalf in accordance with applicable laws.
            </p>
            <h3 className="font-bold text-xl md:text-2xl">Request Verification</h3>
            <p>Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. We will only use personal information provided in your request to verify your identity or authority to make the request. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes.</p>
            <p>If you submit the request through an authorised agent, we may need to collect additional information to verify your identity before processing your request and the agent will need to provide a written and signed permission from you to submit such request on your behalf.</p>
          </div>

          <div id="policy-11" className="scroll-my-20 flex flex-col gap-4">
            <h2 className="font-bold text-2xl md:text-3xl">11. Do we make updates to this notice?</h2>
            <p>
              We may update this privacy notice from time to time.
              The updated version will be indicated by an updated 'Revised' date at the top of this privacy notice.
              If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification.
              We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
            </p>
          </div>

          <div id="policy-12" className="scroll-my-20 flex flex-col gap-4">
            <h2 className="font-bold text-2xl md:text-3xl">12. How can you contact us about this notice?</h2>
            <p>
              If you have questions or comments about this notice, you may contact our Data Protection Officer (DPO) by the contact form listed in the website.
            </p>
          </div>

          <div id="policy-13" className="scroll-my-20 flex flex-col gap-4">
            <h2 className="font-bold text-2xl md:text-3xl">13. How can you review, update or delete the data we collect from you?</h2>
            <p>
              Based on the applicable laws of your country or state of residence in the US, you may have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information.
              You may also have the right to withdraw your consent to our processing of your personal information.
              These rights may be limited in some circumstances by applicable law.
              To request to review, update, or delete your personal information, please visit: <Link to="https://acsmarine.sg" className="underline hover:text-acsm-primary" target="_blank" hideCrawl>acsmarine.sg</Link>.
            </p>
          </div>
        </div>
      </Container>
    </>
  )
}
