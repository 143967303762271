import * as React from "react"
import { Meta } from "@allied/react-web/Meta"
import { useAutoPosition } from "../hooks"
import { Info } from "../components/Page"

export default function NotFound(): React.JSX.Element {
  useAutoPosition()

  return (
    <>
      <Meta>
        {{
          title: "404 Page not found"
        }}
      </Meta>
      {/* 
        @note: do not remove, make sure to match the html element on the homepage
        @issue: react-snap do not handle 404 page correctly
        @ref: https://gitlab.allied.com.sg/web/acem-singapore/-/merge_requests/30
      */}
      <div>
        <Info />
      </div>
    </>
  )
}