import * as React from "react"
import { ButtonLink } from "@allied/react-web/Button"

type InfoProps = {
  title?: React.ReactNode
  subtitle?: React.ReactNode
  action?: React.ReactNode
}

export function Info(props: InfoProps): React.JSX.Element {
  const {
    title = "404",
    subtitle = "Oops! Page not found",
    action = "Back to Home"
  } = props

  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <div className="my-4 flex flex-col gap-2 justify-center items-center">
        <h1 className="text-6xl md:text-8xl">
          {title}
        </h1>
        <h2 className="text-xl md:text-3xl">
          {subtitle}
        </h2>
      </div>
      <div className="my-4">
        <ButtonLink to="/" size="lg">
          {action}
        </ButtonLink>
      </div>
    </div>
  )
}