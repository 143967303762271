import * as React from "react"
import { ButtonLink } from "@allied/react-web/Button"
import { Container } from "@allied/react-web/Container"
import { Meta } from "@allied/react-web/Meta"
import { useHashFragment } from "@allied/react-web/hooks"
import { useAutoPosition } from "../hooks"
import Hero from "../components/Contact/Hero"

export default function Home(): React.JSX.Element {
  useAutoPosition()
  useHashFragment()

  return (
    <>
      <Meta>
        {{
          title: "Home",
          description: "Home"
        }}
      </Meta>

      {/* 
        @note: do not remove, make sure to match the html element on the 404 page
        @issue: react-snap do not handle 404 page correctly
        @ref: https://gitlab.allied.com.sg/web/acem-singapore/-/merge_requests/30
      */}
      <div></div>

      <div className="p-4">
        <div className="w-full min-h-full h-[800px] sm:min-h-[600px] sm:h-[calc(100vh_-120px)] sm:max-h-[800px] bg-cover bg-no-repeat bg-right-top lg:bg-left-top rounded-3xl p-6 lg:p-20"
          style={{ backgroundImage: `url(${"/image/acsm-hero-about.jpg"})` }}>
          <div className="w-full lg:w-2/3 xl:w-1/2 h-full flex flex-col justify-end lg:justify-center items-start lg:items-start gap-4 lg:gap-8">
            <div className="flex flex-col gap-2 mb-2">
              <h1 className="text-white text-2xl lg:!text-6xl font-bold font-verdana [text-shadow:3px_3px_6.6px_rgba(0,_0,_0,_0.25)]">
                ACS Marine & <br />
                Shipping Pte Ltd
              </h1>
              <h2 className="text-acsm-primary text-base lg:!text-2xl font-bold font-verdana">
                Italian Premium Quality Lubricants
              </h2>
            </div>
            <p className="text-white text-sm lg:!text-lg font-poppins [text-shadow:3px_3px_6.6px_rgba(0,_0,_0,_0.25)]">
              ACS Marine & Shipping Pte Ltd is the Authorised Distributor for ENI Marine Lubricants in Singapore.
              Being an authorized distributor allows ACS Marine & Shipping Pte Ltd to have direct access to ENI product range, ensuring that we can provide our customers with genuine and high-quality products.
              This not only guarantees the reliability and performance of the marine lubricants but also enables us to offer competitive pricing and a wide range of premium Italian products to meet the diverse needs of our clients with vessels calling Singapore.
            </p>
            <div className="flex justify-center items-center">
              <ButtonLink to="/product" variant="primary">
                View products
              </ButtonLink>
            </div>
          </div>
        </div>
      </div>

      <Container size="md">
        <div className="w-full px-0 py-4 lg:!p-16 xl:!p-24 bg-white">
          <div className="flex flex-col gap-8 my-8 lg:my-14">
            <div className="rounded-3xl bg-acsm-primary grid grid-cols-12 gap-2 justify-center items-center">
              <div className="col-span-12 lg:!col-span-5 p-6 lg:!p-16">
                <img src="/image/eni-logo.png" alt="eni" className="w-40 lg:!w-80 h-40 lg:!h-80 mx-auto" />
              </div>
              <div className="col-span-12 lg:!col-span-7 p-6 lg:!p-16">
                <div className="flex flex-col gap-4">
                  <h2 className="text-base lg:!text-2xl text-acsm-red font-bold">
                    About ENI
                  </h2>
                  <p className="text-sm lg:!text-lg">
                    ENI is the largest petroleum company in Italy which is partly owned by the Italian Government.
                    ENI is short for the company’s original full name Ente Nazionale Idrocarburi (National Hydrocarbons Agency) which handled the AGIP brand familiar to most since 1926.
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-3xl bg-acsm-primary grid grid-cols-12 gap-2 justify-center items-center">
              <div className="col-span-12 lg:!col-span-6 lg:order-last h-full">
                <img src="/image/acsm-card-about.jpg" alt="eni-about"
                  className="object-cover h-full rounded-tl-3xl rounded-tr-3xl lg:!rounded-tl-none lg:!rounded-br-3xl" />
              </div>
              <div className="col-span-12 lg:!col-span-6 p-6 lg:!p-16">
                <div className="flex flex-col gap-4">
                  <h2 className="text-base lg:text-2xl text-acsm-red font-bold">
                    Why ENI?
                  </h2>
                  <p className="text-sm lg:!text-lg">
                    Aside from being Italy's largest petroleum company, they are also the 2nd largest in the European Union and ranked 61 in the Fortune Global 500 of 2023.
                  </p>
                  <p className="text-sm lg:!text-lg">
                    They were also known globally for their premium quality lubricant products, previously marketed under the <span className="font-bold italic">AGIP</span> brand.
                    Their desire to expand their marine presence in Asia makes them the ideal partner for collaboration.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Hero />
      </Container>
    </>
  )
}