import * as React from "react"
import {
  RouteProps, Route,
  Navigate, NavigateProps,
  generatePath, useParams
} from "react-router-dom"

import Guest from "./layouts/Guest"
import NotFound from "./pages/NotFound"
import ComingSoon from "./pages/ComingSoon"
import Home from "./pages/Home"
import Contact from "./pages/Contact"
import CategoryDetail from "./pages/CategoryDetail"
import Product from "./pages/Product"
import ProductDetail from "./pages/ProductDetail"
import PrivacyPolicy from "./pages/PrivacyPolicy"

const NavigateParams = ({ to, ...props }: NavigateProps) => {
  const params = useParams()
  return <Navigate {...props} to={generatePath(to.toString(), params)} />
}

const guests: RouteProps[] = [
  { path: "/", element: <Home /> },
  { path: "/contact", element: <Contact /> },
  { path: "/category", element: <NavigateParams replace to="/product" /> },
  { path: "/category/:slug", element: <CategoryDetail /> },
  { path: "/product", element: <Product /> },
  { path: "/product/:slug", element: <ProductDetail /> },
  { path: "/privacy-policy", element: <PrivacyPolicy /> },
  { path: "/terms-conditions", element: <ComingSoon /> },
  { path: "*", element: <NotFound /> },
]

export const routes: RouteProps[] = [
  {
    path: "/",
    element: <Guest />,
    children: guests.map((route, i: number) => {
      return <Route {...route} key={`guest-item-${i}`} />
    })
  },
]
