import * as React from "react"
import { Meta } from "@allied/react-web/Meta"
import { Container } from "@allied/react-web/Container"
import { Link } from "@allied/react-web/Router"
import { useHashFragment } from "@allied/react-web/hooks"
import { useAutoPosition } from "../hooks"
import Hero from "../components/Contact/Hero"
import { ProductService } from "../services/static/Product"

const productService = new ProductService()

type CategoryItem = {
  name: string
  slug: string
  icon: any
}

export default function Product(): React.JSX.Element {
  const [categories, setCategories] = React.useState<CategoryItem[]>([])

  React.useEffect(() => {
    async function searchCategories() {
      const searchCategories = await productService.SearchCategory({})
      if (searchCategories.error) {
        return
      }

      setCategories(searchCategories.data.items)
    }

    searchCategories()
  }, [])

  useAutoPosition()
  useHashFragment()

  return (
    <>
      <Meta>
        {{
          title: "Product",
          description: "Product"
        }}
      </Meta>

      <Container size="md">
        <div className="px-0 py-4 lg:!p-16 xl:!p-24">
          <h1 className="text-2xl text-acsm-red lg:!text-5xl mb-6">
            Products
          </h1>
          <hr className="my-2 border-black" />
          <div className="grid grid-cols-12 auto-rows-fr gap-6 md:!gap-12 xl:!gap-24 my-8 lg:!my-14">
            {
              categories.map((category, i: number) => {
                return (
                  <React.Fragment key={`category-item-${i}`}>
                    <div className={
                      "col-span-6 lg:!col-span-4 " +
                      "relative w-full h-full rounded-3xl " +
                      "before:absolute before:w-full before:h-full before:rounded-3xl before:bg-acsm-primary before:z-[-1] before:content-[''] " +
                      "before:top-0 before:transition-all before:duration-500 before:hover:h-[75%] before:hover:top-[20%]"
                    }>
                      <Link to={`/category/${category.slug}`}
                        className="flex flex-col justify-center items-center gap-4 p-4 lg:p-10">
                        <img src={category.icon} alt="category"
                          className="w-32 h-32 lg:!w-36 lg:!h-36 xl:!w-42 xl:!h-42 2xl:!w-52 2xl:!h-52 mx-auto" />
                        <span className="text-base lg:!text-2xl text-center">
                          {category.name}
                        </span>
                      </Link>
                    </div>
                  </React.Fragment>
                )
              })
            }
          </div>
        </div>

        <Hero />
      </Container>
    </>
  )
}