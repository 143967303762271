import * as React from "react"
import { FaEnvelope } from "react-icons/fa6"
import { Button, ButtonLink } from "@allied/react-web/Button"
import { Container } from "@allied/react-web/Container"
import { Radio } from "@allied/react-web/Check"
import { Field, FieldNotif } from "@allied/react-web/Field"
import { InputText, InputPhone, InputEmail } from "@allied/react-web/Input"
import { Meta } from "@allied/react-web/Meta"
import { Loader } from "@allied/react-web/Loader"
import { Textarea } from "@allied/react-web/Textarea"
import { useValidator, Validator, useHashFragment, useRecaptcha } from "@allied/react-web/hooks"
import { useAutoPosition } from "../hooks"
import { toast } from "../components/Toast"
import { ContactService } from "../services/api/Contact"

const contactService = new ContactService({
  host: process.env.REACT_APP_API_HOST
})

export default function Contact(): React.JSX.Element {
  const [recipients, setRecipients] = React.useState([
    {
      name: "Product Enquiry",
      value: "sales",
      selected: true,
    },
    {
      name: "Callback Request",
      value: "callback",
      selected: false,
    },
    {
      name: "Other",
      value: "other",
      selected: false,
    }
  ])
  const [enquiryForm, setEnquiryForm] = React.useState({
    loading: false,
    name: "",
    email: "",
    phone: "",
    message: "",
    about: "Product Enquiry",
  })
  const [enquiryNotif, setEnquiryNotif] = React.useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    about: "",
  })
  const [enquiryValidator] = useValidator({
    name: Validator.string().required().min(3).max(256),
    email: Validator.string().required().email().min(3).max(256),
    phone: Validator.string().required().phone("SG").min(5).max(17).trim(),
    message: Validator.string().required().min(3).max(2048),
    about: Validator.string().required(),
  })

  useAutoPosition()
  useHashFragment()
  useRecaptcha({
    siteId: process.env.REACT_APP_RECAPTCHAV3_SITE
  })

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target
    setEnquiryForm({ ...enquiryForm, [name]: value })
  }

  async function submitEnquiry(form: typeof enquiryForm & { verificationToken: string }) {
    toast.info(
      "Submiting your enquiry, please wait a moment",
      { className: "toast-mesasge" }
    )
    setEnquiryForm((prevState) => {
      return {
        ...prevState,
        loading: true,
      }
    })
    const sendEnquiry = await contactService.SendEnquiry(form)
    setEnquiryForm((prevState) => {
      return {
        ...prevState,
        loading: false,
      }
    })
    if (sendEnquiry.error) {
      console.error("An error occurred while submitting the form", sendEnquiry.error)
      toast.error(
        `Failed send enquiry: ${sendEnquiry.error.message}`,
        { className: "toast-mesasge" }
      )
      return
    }

    setEnquiryForm((prevState) => {
      return {
        ...prevState,
        name: "",
        email: "",
        phone: "",
        about: "sales",
        message: "",
      }
    })
    toast.success(
      "Enquiry Submitted Successfully. Our team will get back to you shortly",
      { className: "toast-mesasge" }
    )
  }

  const handleEnquirySubmit = (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()

      setEnquiryNotif({
        name: "",
        email: "",
        phone: "",
        message: "",
        about: "",
      })

      const validateRes = enquiryValidator.validate({
        name: enquiryForm.name,
        email: enquiryForm.email,
        phone: enquiryForm.phone,
        message: enquiryForm.message,
        about: enquiryForm.about
      })
      if (validateRes.error) {
        const err = validateRes.error

        if (err instanceof Validator.ValidationError) {
          setEnquiryNotif((prevState) => {
            return {
              ...prevState,
              [err.path]: err.errors.join("\n")
            }
          })
        } else {
          console.warn("unexpected error: ", err)
        }

        return
      }

      // @ts-ignore
      if (!grecaptcha) {
        toast.error(
          "Failed initialized recaptcha",
          { className: "toast-mesasge" }
        )
        return
      }

      // @ts-ignore
      grecaptcha.ready(async () => {
        // @ts-ignore
        const token = await grecaptcha.execute(process.env.REACT_APP_RECAPTCHAV3_SITE, { action: "submit" })
        if (!token) {
          toast.error(
            "Failed verify reCAPTCHA, please try again.",
            { className: "toast-mesasge" }
          )
          return
        }

        submitEnquiry({
          ...enquiryForm,
          verificationToken: token
        })
      })
    } catch (error: any) {
      console.error("Failed submit enquiry", error)
      toast.error(
        "Failed submit enquiry",
        { className: "toast-mesasge" }
      )
    }
  }

  return (
    <>
      <Meta>
        {{
          title: "Contact",
          description: "Contact"
        }}
      </Meta>

      <Container size="md">
        <div className="px-0 py-4 lg:!p-16 xl:!p-24">
          <div className="w-full flex flex-col gap-2 my-6">
            <h1 className="text-2xl text-acsm-red lg:!text-5xl">
              Get in touch
            </h1>
            <p className="text-lg mb-3">
              Got any questions? Fill this form to reach out.
            </p>
            <hr className="my-2 border-black" />
          </div>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 md:!col-span-6 lg:!col-span-7 xl:!col-span-7 md:order-last">
              <div className="flex flex-col p-10 lg:p-16">
                <div className="mb-4 lg:!mb-10 relative text-center">
                  <img src="/image/island.png" alt="island" />
                  <span className="absolute top-1/2 left-1/2 text-sm lg:!text-lg"
                    style={{ transform: "translate(-50%, -50%)" }}>
                    Based in Singapore
                  </span>
                </div>
                <ButtonLink to="mailto:sales@acsmarine.sg" variant="primary" size="sm"
                  appendClassNames="flex flex-row justify-start items-center gap-2">
                  <FaEnvelope className="block h-4 w-4" aria-hidden="true" />
                  <span>sales@acsmarine.sg</span>
                </ButtonLink>
              </div>
            </div>
            <div className="col-span-12 md:!col-span-6 lg:!col-span-5 xl:!col-span-5">
              <Loader lazy>
                <form onSubmit={handleEnquirySubmit}>
                  <div className="flex flex-col gap-3">
                    <Field>
                      <label htmlFor="name">
                        Name <span className="text-red-600">*</span>
                      </label>
                      <InputText
                        id="name"
                        name="name"
                        placeholder="Enter your name"
                        value={enquiryForm.name}
                        onChange={handleInputChange}
                        disabled={enquiryForm.loading}
                        required />
                      <FieldNotif>{enquiryNotif.name}</FieldNotif>
                    </Field>
                    <Field>
                      <label htmlFor="email">
                        Email <span className="text-red-600">*</span>
                      </label>
                      <InputEmail
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                        value={enquiryForm.email}
                        onChange={handleInputChange}
                        disabled={enquiryForm.loading}
                        required />
                      <FieldNotif>{enquiryNotif.email}</FieldNotif>
                    </Field>
                    <Field>
                      <label htmlFor="phone">
                        Phone number <span className="text-red-600">*</span>
                      </label>
                      <InputPhone
                        id="phone"
                        name="phone"
                        placeholder="Enter your phone number"
                        value={enquiryForm.phone}
                        onUpdate={(m) => {
                          setEnquiryForm((prevState) => {
                            return {
                              ...prevState,
                              phone: m.phone_number
                            }
                          })
                        }}
                        disabled={enquiryForm.loading}
                        required />
                      <FieldNotif>{enquiryNotif.phone}</FieldNotif>
                    </Field>
                    <Field>
                      <label htmlFor="about">
                        What is it about? <span className="text-red-600">*</span>
                      </label>
                      <div id="about">
                        {
                          recipients.map((recipient, i: number) => {
                            return (
                              <React.Fragment key={`recipient-item-${i}`}>
                                <Radio
                                  name="about"
                                  id={`recipient-${recipient.value}`}
                                  value={recipient.value}
                                  label={recipient.name}
                                  checked={recipient.selected}
                                  disabled={enquiryForm.loading}
                                  onChange={(e) => {
                                    setRecipients((prevItems) => {
                                      return prevItems.map((item, j: number) => {
                                        return {
                                          ...item,
                                          selected: (i === j)
                                        }
                                      })
                                    })
                                    setEnquiryForm((prevState) => {
                                      return {
                                        ...prevState,
                                        about: recipient.name
                                      }
                                    })
                                  }}
                                  required
                                />
                              </React.Fragment>
                            )
                          })
                        }
                      </div>
                      <FieldNotif>{enquiryNotif.about}</FieldNotif>
                    </Field>
                    <Field>
                      <label htmlFor="message">
                        Message <span className="text-red-600">*</span>
                      </label>
                      <Textarea
                        id="message"
                        name="message"
                        placeholder="Enter your message"
                        value={enquiryForm.message}
                        onChange={handleInputChange}
                        disabled={enquiryForm.loading}
                        required>
                      </Textarea>
                      <FieldNotif>{enquiryNotif.message}</FieldNotif>
                    </Field>
                    <Field>
                      <div className="g-recaptcha"
                        data-sitekey={process.env.REACT_APP_RECAPTCHAV3_SITE}
                        data-size="invisible"
                        data-action="submit">
                      </div>
                    </Field>
                    <Button type="submit" size="lg" disabled={enquiryForm.loading}>
                      Send a Message
                    </Button>
                  </div>
                </form>
              </Loader>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}
