import * as React from "react"
import { useParams } from "react-router-dom"
import { GoLinkExternal } from "react-icons/go"
import { Meta } from "@allied/react-web/Meta"
import { Button, ButtonLink } from "@allied/react-web/Button"
import { Container } from "@allied/react-web/Container"
import { useHashFragment } from "@allied/react-web/hooks"
import { useAutoPosition } from "../hooks"
import Hero from "../components/Contact/Hero"
import { ProductService } from "../services/static/Product"

const productService = new ProductService()

type ProductItem = {
  name: string
  description?: string
  slug: string
  thumbnail: any
  pds_file?: string
  sds_file?: string
}

export default function ProductDetail(): React.JSX.Element {
  const [product, setProduct] = React.useState<ProductItem>(null)
  const { slug } = useParams()

  React.useEffect(() => {
    async function getProduct() {
      const getProduct = await productService.GetProduct({
        slug
      })
      if (getProduct.error) {
        return
      }

      setProduct(getProduct.data)
    }

    getProduct()
  }, [slug])

  useAutoPosition()
  useHashFragment()

  return (
    <>
      {
        !product &&
        <div className="min-h-screen flex flex-col justify-center items-center">
          <div className="h-screen flex flex-col justify-center items-center">
            <div className="my-4 flex flex-col gap-4 justify-center items-center">
              <h1 className="text-8xl">
                404
              </h1>
              <h2 className="text-2xl">
                Oops! Page not found
              </h2>
            </div>
            <div className="my-6">
              <ButtonLink to="/" size="lg">
                Back to Homepage
              </ButtonLink>
            </div>
          </div>
        </div>
      }

      {
        product &&
        <>
          <Meta>
            {{
              title: product.name,
              description: product.description
            }}
          </Meta>

          <Container size="md">
            <div className="px-0 py-4 lg:!p-16 xl:!p-24">
              <div className="grid grid-cols-12 gap-4 my-8 lg:!my-14">
                <div className="col-span-12 lg:!col-span-8 lg:order-last">
                  <h1 className="text-center text-2xl text-acsm-red lg:!text-5xl mb-1 lg:!mb-3">
                    {product.name}
                  </h1>
                  {
                    product.description &&
                    <p className="text-center text-lg mb-6">
                      {product.description}
                    </p>
                  }
                  <hr className="my-2 border-black" />
                  {
                    (product.pds_file || product.sds_file) &&
                    <div className="hidden lg:!flex flex-row flex-wrap gap-2 justify-center items-center my-8">
                      {
                        product.pds_file ?
                          <ButtonLink to={product.pds_file} size="lg" target="_blank"
                            appendClassNames="flex flex-row gap-2 justify-between items-center" hideCrawl>
                            <span>Product Data Sheet (PDS)</span>
                            <GoLinkExternal className="w-4 h-4" aria-hidden="true" />
                          </ButtonLink>
                          :
                          <Button size="lg" appendClassNames="flex flex-row gap-2 justify-between items-center" disabled>
                            <span>PDS Coming Soon</span>
                            <GoLinkExternal className="w-4 h-4" aria-hidden="true" />
                          </Button>
                      }
                      {
                        product.sds_file ?
                          <ButtonLink to={product.sds_file} size="lg" target="_blank"
                            appendClassNames="flex flex-row gap-2 justify-between items-center" hideCrawl>
                            <span>Safety Data Sheet (SDS)</span>
                            <GoLinkExternal className="w-4 h-4" aria-hidden="true" />
                          </ButtonLink>
                          :
                          <Button size="lg" appendClassNames="flex flex-row gap-2 justify-between items-center" disabled>
                            <span>SDS Coming Soon</span>
                            <GoLinkExternal className="w-4 h-4" aria-hidden="true" />
                          </Button>
                      }
                    </div>
                  }
                </div>
                <div className="col-span-12 lg:!col-span-4">
                  <div className="flex justify-center items-center">
                    <img src={product.thumbnail} alt="product"
                      className="w-full h-full" />
                  </div>

                  <div className="flex flex-col gap-2 justify-center items-center my-4">
                    <div className="flex flex-col gap-2 justify-center items-center">
                      <img src="/image/marine-ship.png" alt="marine" className="w-24 h-24" />
                      <span className="text-base lg:!text-2xl">
                        Marine
                      </span>
                    </div>
                  </div>

                  {
                    (product.pds_file || product.sds_file) &&
                    <div className="flex lg:!hidden flex-row flex-wrap gap-2 justify-center items-center my-8">
                      {
                        product.pds_file &&
                        <ButtonLink to={product.pds_file} size="lg" target="_blank"
                          appendClassNames="flex flex-row gap-2 justify-between items-center" hideCrawl>
                          <span>Product Data Sheet (PDS)</span>
                          <GoLinkExternal className="w-4 h-4" aria-hidden="true" />
                        </ButtonLink>
                      }
                      {
                        product.sds_file &&
                        <ButtonLink to={product.sds_file} size="lg" target="_blank"
                          appendClassNames="flex flex-row gap-2 justify-between items-center" hideCrawl>
                          <span>Safety Data Sheet (SDS)</span>
                          <GoLinkExternal className="w-4 h-4" aria-hidden="true" />
                        </ButtonLink>
                      }
                    </div>
                  }
                </div>
              </div>
            </div>

            <Hero />
          </Container>
        </>
      }
    </>
  )
}