import * as React from "react"
import { Meta } from "@allied/react-web/Meta"
import { useAutoPosition } from "../hooks"
import { Info } from "../components/Page"

export default function ComingSoon(): React.JSX.Element {

  useAutoPosition()

  return (
    <>
      <Meta>
        {{
          title: "418 Coming Soon"
        }}
      </Meta>

      <Info title="418" subtitle="Page under construction" />
    </>
  )
}