import * as React from "react"
import { ButtonLink } from "@allied/react-web/Button"

export default function Hero(): React.JSX.Element {
  return (
    <div className="w-full h-[400px] md:h-[500px] my-12">
      <div className="w-full relative">
        <div className="rounded-3xl bg-cover bg-left-top h-[300px] md:h-[420px]" style={{ backgroundImage: `url(${"/image/acsm-card-contact.jpg"})` }}>
        </div>
        <div className="absolute right-0 -bottom-28 md:-bottom-20 w-full lg:max-w-[750px] lg:rounded-tl-3xl rounded-b-3xl bg-acsm-primary p-6 lg:!p-8">
          <div className="flex flex-col gap-4">
            <p className="text-base lg:!text-lg">
              Contact us today to get a non-obligation quotation for your vessel’s marine lubricants requirements.
            </p>
            <div>
              <ButtonLink to="/contact" variant="secondary">
                Product Enquiry
              </ButtonLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}