import * as React from "react"
import { useLocation } from "react-router-dom"

export function useAutoPosition() {
  const { pathname } = useLocation()

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }, [pathname])
}
